import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from '../../Button';
import Modal from '..';

import styles from './style.scss';

function ActionModal(props) {
  return (
    <Modal {...props} className={clsx(styles.HuiActionModal, props.className)}>
      <React.Fragment>
        {props.children}
        <div
          className={clsx(
            styles['HuiActionModal__button-container'],
            styles[
              props.verticalButtons
                ? 'HuiActionModal__button-container--vertical'
                : 'HuiActionModal__button-container--horizontal'
            ]
          )}
        >
          {!props.singleButtonMode && (
            <Button
              size='medium'
              color='primary'
              variant='outlined'
              className={clsx(
                styles.HuiActionModal__button,
                styles['HuiActionModal__button--secondary']
              )}
              label={props.secondaryButtonText}
              onClick={() => !props.loading && props.onClickSecondaryButton()}
              {...props.secondaryButtonProps}
            />
          )}
          <Button
            size='medium'
            color='primary'
            variant='contained'
            className={clsx(styles.HuiActionModal__button)}
            label={props.primaryButtonText}
            isLoading={props.isLoading}
            isDisabled={props.isLoading}
            onClick={props.onClickPrimaryButton}
            {...props.primaryButtonProps}
          />
        </div>
      </React.Fragment>
    </Modal>
  );
}

ActionModal.defaultProps = {
  isLoading: false,
  singleButtonMode: false,
  verticalButtons: false,
  primaryButtonText: 'Primary',
  secondaryButtonText: 'Secondary',
  onClickPrimaryButton: () => {},
  onClickSecondaryButton: () => {},
  primaryButtonProps: {},
  secondaryButtonProps: {}
};
ActionModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  isLoading: PropTypes.bool,
  singleButtonMode: PropTypes.bool,
  verticalButtons: PropTypes.bool,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  onClickPrimaryButton: PropTypes.func,
  onClickSecondaryButton: PropTypes.func,
  primaryButtonProps: PropTypes.object,
  secondaryButtonProps: PropTypes.object
};

export default ActionModal;

import constants from '../themes/_constants.module.scss';

export const scaleDesignWidth = (pxValue, includePxUnit = false) => {
  const enableScaling = constants?.enableScaling === 'true';
  if (enableScaling) {
    const designWidth = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--ui-kit-design-width');
    const maxScaleWidth = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--ui-kit-scale-max-width');
    if (!designWidth) {
      return pxValue;
    }
    const parsedPxValue = parseInt(pxValue);
    const designMobileWidth = parseInt(designWidth);
    const maxScaleWidthParsed = parseInt(maxScaleWidth) || 5000;

    const scaledSize = (parsedPxValue * window.innerWidth) / designMobileWidth;
    const maxPossibleSize = (parsedPxValue * maxScaleWidthParsed) / designMobileWidth;

    if (parsedPxValue < 0) {
      const maxResult = Math.max(scaledSize, maxPossibleSize);
      return includePxUnit ? `${maxResult}px` : maxResult;
    }
    const minResult = Math.min(scaledSize, maxPossibleSize);
    return includePxUnit ? `${minResult}px` : minResult;
  } else {
    return pxValue;
  }
};

import React from 'react';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

// Local styles
import styles from './style.scss';

const Ticker = (props) => {
  const getRoundedCornersClass = () => {
    if (props.roundedCorners) {
      return styles['HuiTicker--rounded'];
    }
    return null;
  };
  const getTickerColorClass = () => {
    switch (props.color) {
      case 'warning':
        return styles['HuiTicker--warning'];
      case 'error':
        return styles['HuiTicker--error'];
      case 'neutral':
        return styles['HuiTicker--neutral'];
      case 'success':
      default:
        return styles['HuiTicker--success'];
    }
  };
  const getTickerLabelColorClass = () => {
    switch (props.color) {
      case 'warning':
        return styles['HuiTicker__label--warning'];
      case 'error':
        return styles['HuiTicker__label--error'];
      case 'neutral':
        return styles['HuiTicker__label--neutral'];
      case 'success':
      default:
        return styles['HuiTicker__label--success'];
    }
  };
  // Event handler methods
  const onClickRightIcon = (event) => {
    event.stopPropagation();
    isFunction(props.onClickRightIcon) && props.onClickRightIcon();
  };
  // Render
  return (
    <button
      className={clsx(
        styles.HuiTicker,
        getRoundedCornersClass(),
        getTickerColorClass()
      )}
      onClick={props.onClick}
    >
      <div className={styles.HuiTicker__left}>
        {props.faLeftIcon && (
          <FontAwesomeIcon
            icon={props.faLeftIcon}
            className={clsx(
              styles.HuiTicker__leftIcon,
              getTickerLabelColorClass()
            )}
          />
        )}
        <p
          className={clsx(styles.HuiTicker__label, getTickerLabelColorClass())}
        >
          {props.label}
        </p>
      </div>
      {props.faRightIcon && (
        <div
          onClick={onClickRightIcon}
          className={clsx(styles.HuiTicker__rightIconButton)}
        >
          <FontAwesomeIcon
            icon={props.faRightIcon}
            className={clsx(
              styles.HuiTicker__rightIcon,
              getTickerLabelColorClass()
            )}
          />
        </div>
      )}
    </button>
  );
};

Ticker.defaultProps = {
  color: 'success',
  roundedCorners: true,
  onClick: () => null,
  onClickRightIcon: () => null
};

Ticker.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  faLeftIcon: PropTypes.object,
  faRightIcon: PropTypes.object,
  roundedCorners: PropTypes.bool,
  onClickRightIcon: PropTypes.func,
  color: PropTypes.oneOf(['neutral', 'error', 'success', 'warning'])
};

export default Ticker;

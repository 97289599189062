import React from 'react';
import PropTypes from 'prop-types';
import {isString, isFunction} from 'lodash';
import clsx from 'clsx';

// Global utils
import {scaleDesignWidth} from '../../utils/utils';

// Local styles
import styles from './style.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const AdvanceTicker = (props) => {
  const getRoundedCornersClass = () => {
    if (props.roundedCorners) {
      return styles['HuiAdvanceTicker--rounded'];
    }
    return null;
  };
  // Event handler methods
  const onClickCta = (event) => {
    event.stopPropagation();
    isFunction(props.onClickCta) && props.onClickCta();
  };
  // Render
  return (
    <button
      onClick={props.onClick}
      style={isString(props.ctaLabel) ? null : {paddingRight: scaleDesignWidth(2)}}
      className={clsx(styles.HuiAdvanceTicker, getRoundedCornersClass())}
    >
      {props.faLeftIcon && (
        <FontAwesomeIcon
          icon={props.faLeftIcon}
          className={clsx(styles.HuiAdvanceTicker__leftIcon)}
        />
      )}
      <p className={clsx(styles.HuiAdvanceTicker__label)}>{props.label}</p>
      {isString(props.ctaLabel) && (
        <div
          onClick={onClickCta}
          className={clsx(styles.HuiAdvanceTicker__ctaButton)}
        >
          <p className={clsx(styles.HuiAdvanceTicker__ctaLabel)}>
            {props.ctaLabel}
          </p>
        </div>
      )}
    </button>
  );
};

AdvanceTicker.defaultProps = {
  color: 'success',
  onClick: () => null,
  roundedCorners: true,
  onClickCta: () => null
};

AdvanceTicker.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onClickCta: PropTypes.func,
  ctaLabel: PropTypes.string,
  className: PropTypes.string,
  faLeftIcon: PropTypes.object,
  roundedCorners: PropTypes.bool,
  color: PropTypes.oneOf(['neutral', 'error', 'success', 'warning'])
};

export default AdvanceTicker;

import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import styles from './style.scss';

const Switch = (props) => {

    const [switcher, setSwitcher] = useState(false);
    const {checked, onChange, disabled, className, label} = props;

    useEffect(() => {
        setSwitcher(checked);
    }, [checked]);

    const triggerswitcher = () => {
        if (disabled){
            return;
        }
        setSwitcher( !switcher );
        if ( typeof onChange === 'function' ) {
            onChange(!switcher);
        }
    };

    return (
        <div 
            className={clsx(
                styles['switch-container'],
                className
            )}
        >
            <div 
                className={clsx(
                    styles['wrg-switcher'],
                    switcher && styles['wrg-switcher--checked'],
                    disabled && styles['wrg-switcher--disabled'],
                )}
                onClick={triggerswitcher}
            >
                <div className={clsx(styles["wrg-switcher-container"])}></div>
                <div 
                    className={clsx(styles["wrg-switcher-circle"])}
                >
                </div>
                <input className={clsx(styles["wrg-switcher-input"])} type="checkbox" aria-label="switcher Button" />
            </div>
            <div className={clsx(styles["label"])}>{label}</div>
        </div>
    );
};

Switch.propTypes = {
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
};

export default Switch;